/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

.settings.connectionHistory .filter {

    /* IE10 */
    display: -ms-flexbox;
    -ms-flex-align: stretch;
    -ms-flex-direction: row;

    /* Ancient Mozilla */
    display: -moz-box;
    -moz-box-align: stretch;
    -moz-box-orient: horizontal;

    /* Ancient WebKit */
    display: -webkit-box;
    -webkit-box-align: stretch;
    -webkit-box-orient: horizontal;

    /* Old WebKit */
    display: -webkit-flex;
    -webkit-align-items: stretch;
    -webkit-flex-direction: row;

    /* W3C */
    display: flex;
    align-items: stretch;
    flex-direction: row;

}

.settings.connectionHistory .filter .search-string {
    -ms-flex: 1 1 auto;
    -moz-box-flex: 1;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
}

.settings.connectionHistory .filter .search-button,
.settings.connectionHistory .filter button {

    -ms-flex: 0 0 auto;
    -moz-box-flex: 0;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;

    margin-top: 0;
    margin-bottom: 0;

}

.settings.connectionHistory .history-list {
    width: 100%;
}

.settings.connectionHistory a.history-session-recording {
    color: #0000ee;
}

.settings.connectionHistory a.history-session-recording::after {
    display: inline-block;
    content: ' ';
    width: 1em;
    height: 1em;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('images/action-icons/guac-play-link.svg');
    vertical-align: middle;    
}
